import PropTypes from 'prop-types';
import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import Button from '@ui/Button';
import DateLine from '@ui/DateLine';
import Flex from '@ui/Flex';
import RDMD from '@ui/RDMD';

import { LOG } from './shapes';
import classes from './style.module.scss';

const Back = ({ children, className, ...props }) => {
  const history = useHistory();
  const { state = {} } = useLocation();
  let Tag = Link;
  let attrs = { to: '/changelog' };
  if ('fromChangelogList' in state && state.fromChangelogList) {
    Tag = Button;
    attrs = { onClick: () => history.goBack() };
  }
  return (
    <Tag {...props} {...attrs} className={`${className} ${classes.ChangelogPost_back}`}>
      {children}
    </Tag>
  );
};

const ChangelogPost = ({
  changelog: { type, title, user, createdAt, body, slug } = {},
  exactDate,
  isList,
  showAuthor,
  typeIconClasses,
}) => {
  const changelogIcon = exactDate ? '' : 'icon-clock';
  const changelogFormat = exactDate ? { fnsFormat: 'PPP' } : {};
  return (
    <article
      className={`${classes.ChangelogPage} ${classes.ChangelogPage_list} rm-Changelog-post`}
      id={title?.replace(/\s+/g, '-').toLowerCase()}
    >
      {!isList && title ? (
        <Flex align="center" className={classes.ChangelogPage_back} justify="end">
          <Back className="Button Button_sm Button_text" size="sm">
            <span className={`icon-arrow-left2 ${classes['ChangelogPost_back-icon']}`} />
            Back to All
          </Back>
        </Flex>
      ) : (
        <Flex
          align="end"
          className={classes['ChangelogPage_list-date']}
          dateTime={createdAt}
          gap="xs"
          layout="col"
          tag="time"
        >
          <DateLine icon={changelogIcon} time={createdAt} {...changelogFormat} />
          {!!isList && (
            <Flex
              align="center"
              className={`${classes.ChangelogPost_type} ${classes['ChangelogPage_list-type']}`}
              gap="0"
            >
              {!!typeIconClasses[type] && (
                <span
                  className={`${typeIconClasses[type]} ${classes.ChangelogIcon}  ${classes['ChangelogPage_list-icon']}`}
                />
              )}
              {type}
            </Flex>
          )}
        </Flex>
      )}
      <div className={`${classes.ChangelogPost} ${classes['ChangelogPage_list-post']}`}>
        {!!type && !isList && (
          <div className={classes.ChangelogPost_type}>
            {!!typeIconClasses[type] && <span className={`${typeIconClasses[type]} ${classes.ChangelogIcon}`} />}
            {type}
          </div>
        )}
        <header className={classes.ChangelogPost_header}>
          {!isList ? (
            <h1 className={classes.ChangelogPost_title}>{title}</h1>
          ) : (
            <Flex gap="0" layout="col">
              <Link
                to={{
                  pathname: `/changelog/${slug}`,
                  state: { fromChangelogList: true },
                }}
              >
                <h1 className={classes.ChangelogPost_title}>{title}</h1>
              </Link>
              {!!showAuthor && !!user && <address className={classes['ChangelogPost-author']}>by {user.name}</address>}
            </Flex>
          )}
          {!!createdAt && !isList && (
            <DateLine
              className={classes.ChangelogPost_date}
              icon={changelogIcon}
              suffix={!!showAuthor && !!user && `by ${user.name}`}
              time={createdAt}
              {...changelogFormat}
            />
          )}
        </header>
        <section key={slug} className={classes.ChangelogPost_text}>
          <RDMD>{body}</RDMD>
        </section>
      </div>
    </article>
  );
};

ChangelogPost.propTypes = {
  changelog: PropTypes.shape(LOG),
  exactDate: PropTypes.bool,
  isList: PropTypes.bool,
  showAuthor: PropTypes.bool,
  typeIconClasses: PropTypes.object,
};

export default ChangelogPost;
